import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Form, Dropdown, InputGroup} from "react-bootstrap";
import {Container, Row, Col} from "reactstrap";
import axios from "axios";
import {updateFormData} from "../Redux/FormData/action";
import {toast} from "react-toastify";
import PhoneInput from 'react-phone-input-2'
import {BackEndApi} from "../Config/config";
// import 'react-phone-input-2/lib/plain.css'
import {BsEye, BsEyeSlash} from 'react-icons/bs';


const PersonalAndCompanyDetails = ({
                                       nameError,
                                       setNameError,
                                       emailError,
                                       setEmailError,
                                       passwordError,
                                       setPasswordError,
                                       businessNameError,
                                       setBusinessNameError,
                                       phoneNumberError,
                                       setPhoneNumberError,
                                       businessOverviewError,
                                       setBusinessOverviewError
                                   }) => {
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.form);
    const answeringRole = formData.role;
    //const timeZone = formData.timeZone;
    let [selectedGreeting, setSelectedGreeting] = useState();

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (selectedGreeting !== "CUSTOM") {
            setSelectedGreeting(value);
        }

        dispatch(
            updateFormData({
                ...formData,
                [name]: value,
            })
        );
    };

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const custom = urlParams.get("customer_id");

            if (custom) {
                try {
                    const response = await axios.get(
                        `${BackEndApi}payments/${custom}`
                    );
                    const initialData = response.data;
                    dispatch(
                        updateFormData({
                            name: initialData.name,
                            email: initialData.email,
                            phoneNumber: initialData.phoneNumber,
                            accountID: initialData?.accountID,
                            businessName: initialData?.businessName,
                            role: "Receptionist"

                        })
                    );
                } catch (error) {
                    toast.error(error);
                }
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
    }, [formData]);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <Container fluid>
            <Form>
                <Row>
                    <Col xs={12} sm={6}>
                        <div className="p-5 border shadow mb-5 rounded">
                            <div
                                className="main-h4-tag-large d-flex flex-column align-items-center justify-content-center">
                                <div className="text-center mt-1">
                                    <h4 className="main-h4-tag-large">YOUR NEW NUMBER</h4>
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <p className="mb-3 mt-3">IS:</p>
                                        <p className="font-weight-bold">{formData.accountID}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="p-5 border shadow mb-5 rounded">
                            <Form.Group
                                controlId="role"
                                className="d-flex flex-column align-items-center justify-content-center"
                            >
                                <h4 className="main-h4-tag-large">ANSWERING ROLE <span className="text-danger">*</span>
                                </h4>
                                <Dropdown
                                    onSelect={(value) =>
                                        handleInputChange(
                                            {target: {name: "role", value}}
                                        )
                                    }
                                    className=" mt-5 custom-style-settings-menu "
                                >
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic"
                                                     className={"dropdown-color-change"}>
                                        {answeringRole || "Select Role"}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="Answering Service">
                                            Answering Service
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Receptionist">
                                            Receptionist
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Appointment Desk">
                                            Appointment Desk
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Overflow Center">
                                            Overflow Center
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Help Desk">
                                            Help Desk
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Customer Service">
                                            Customer Service
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Customer Care">
                                            Customer Care
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="Sales Agent">
                                            Sales Agent
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                        </div>
                    </Col>
                </Row>

                <div className="p-5 border shadow mb-5 rounded">
                    <h4 className="main-h4-tag-large m-3">GREETING TEXT <span className="text-danger">*</span></h4>
                    <div className="ml-4 custom-p-tag">
                        <Form.Group>
                            <Form.Check
                                type="radio"
                                label={`TYFC "${formData?.businessName || "ABC"}". HMIHY?`}
                                name="greetingText"
                                value={`TYFC "${formData?.businessName || "ABC"}". HMIHY?`}
                                checked={formData?.greetingText === `TYFC "${formData?.businessName || "ABC" }". HMIHY?`}
                                onChange={(e) => handleInputChange(e, "selectedGreeting")}
                            />
                            <Form.Check
                                type="radio"
                                label={`TYFC "${formData?.businessName || "ABC"}". This is "Person Name". HMIHY?`}
                                name="greetingText"
                                value={`TYFC "${formData?.businessName || "ABC"}". This is "Person Name". HMIHY?`}
                                checked={
                                    formData.greetingText ===
                                    `TYFC "${formData?.businessName || "ABC"}". This is "Person Name". HMIHY?`
                                }
                                onChange={(e) => handleInputChange(e)}
                            />
                            <Form.Check
                                type="radio"
                                label={`TYFC. HMIHY?`}
                                name="greetingText"
                                value={`TYFC. HMIHY?`}
                                checked={formData?.greetingText === `TYFC. HMIHY?`}
                                onChange={(e) => handleInputChange(e)}
                            />
                            <Form.Check
                                type="radio"
                                label="Custom"
                                name="greetingText"
                                value="CUSTOM"
                                checked={selectedGreeting === "CUSTOM"}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Form.Group>
                        {selectedGreeting === "CUSTOM" && (
                            <Form.Group controlId="customGreeting">
                                <Form.Control
                                    type="text"
                                    name="greetingText"
                                    placeholder="Type your custom greeting"
                                    value={formData?.customGreeting}
                                    onChange={(e) => handleInputChange(e)}
                                    required
                                />
                            </Form.Group>
                        )}
                    </div>
                </div>

                <div className="p-5 border shadow mb-5 rounded">
                    <h4 className="main-h4-tag-large m-3">ADMIN DETAILS</h4>
                    <div className="ml-3 custom-p-tag">
                        <Form.Group controlId="name">
                            <Form.Label>Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    setNameError(false);
                                }}
                                required
                                isInvalid={nameError}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid name.
                            </Form.Control.Feedback>

                        </Form.Group>

                        <Form.Group controlId="email" className="mt-3">
                            <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    setEmailError(false);
                                }}
                                isInvalid={emailError}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid email address.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="newPassword" className="mt-3 w-100">
                            <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                            <InputGroup className="custom-border-input">
                                <Form.Control
                                    type={passwordVisible ? 'text' : 'password'}
                                    name="newPassword"
                                    value={formData.newPassword}
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        setPasswordError(false);
                                    }}
                                    isInvalid={passwordError}
                                    required
                                />
                                <InputGroup.Text className='custom-border-icons' style={{cursor: "pointer"}}>
                                    {passwordVisible ? (
                                        <BsEyeSlash onClick={togglePasswordVisibility}/>
                                    ) : (
                                        <BsEye onClick={togglePasswordVisibility}/>
                                    )}
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">
                                    Password must contain at least one letter and one number, and be at least 8
                                    characters long
                                </Form.Control.Feedback>
                            </InputGroup>
                            <Form.Control.Feedback type="invalid">
                                Password must contain at least one letter and one number, and be at least 8 characters
                                long
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="companyName" className="mt-3">
                            <Form.Label>Business Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="businessName"
                                value={formData.businessName}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    setBusinessNameError(false);
                                }}
                                isInvalid={businessNameError}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid company name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="phoneNumber" className="mt-3">
                            <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="tel"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    setPhoneNumberError(false);
                                }}
                                isInvalid={phoneNumberError}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please Enter A Valid Phone Number - Ex: 18667480009
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                <div className="p-5 border shadow mb-5 rounded">
                    <h4 className="main-h4-tag-large m-3">COMPANY OVERVIEW</h4>
                    <div className="ml-3 custom-p-tag">
                        <Form.Group controlId="address">
                            <Form.Label>Address - <small className="text-muted">(Optional)</small></Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Form.Group>

                        <Form.Group controlId="phone" className="mt-3">
                            <Form.Label>Phone - <small className="text-muted">(Optional)</small></Form.Label>
                            <Form.Control
                                type="tel"
                                name="businessPhoneNumber"
                                value={formData.businessPhoneNumber}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Form.Group>

                        <Form.Group controlId="email" className="mt-3">
                            <Form.Label>Email - <small className="text-muted">(Optional)</small></Form.Label>
                            <Form.Control
                                type="email"
                                name="businessEmail"
                                value={formData.businessEmail}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Form.Group>

                        <Form.Group controlId="businessOverview" className="mt-3">
                            <Form.Label>Explain your business in 2-3 sentences <span
                                className="text-danger">*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="businessOverview"
                                value={formData.businessOverview}
                                onChange={(e) => {
                                    handleInputChange(e);
                                    setBusinessOverviewError(false);
                                }}
                                isInvalid={businessOverviewError}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please Tell us Something About Your Company.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
            </Form>
        </Container>
    );
};

export default PersonalAndCompanyDetails;