import React, { useEffect } from "react";
import {
    HashRouter as Router,
    Route,
    Routes,
    Navigate,
    BrowserRouter,
} from "react-router-dom";
import Payments from "./views/Payment";
import Onboarding from "./views/Onboarding";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/payment" element={<Payments />} />
                <Route path="/" element={<Navigate to="/payment" />} />
                <Route path="/onboarding" element={<Onboarding />} />
            </Routes>
        </BrowserRouter>
    );
}
