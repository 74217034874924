import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/onboarding.css";


const holidaysData = {
  new_year: { name: "New Year's Day", date: "2023-01-01" },
  memorial_day: { name: "Memorial Day", date: "2023-05-29" },
  independence_day: { name: "Independence Day", date: "2023-07-04" },
  labor_day: { name: "Labor Day", date: "2023-09-04" },
  thanksgiving: { name: "Thanksgiving", date: "2023-11-23" },
  martin_luther_king_day: {
    name: "Martin Luther King Day",
    date: "2023-01-16",
  },
  president_day: { name: "President's Day", date: "2023-02-20" },
  good_friday: { name: "Good Friday", date: "2023-04-07" },
  juneteenth: { name: "Juneteenth", date: "2023-06-19" },
  columbus_day: { name: "Columbus Day", date: "2023-10-09" },
  veterans_day: { name: "Veterans Day", date: "2023-11-11" },
  day_after_thanksgiving: {
    name: "Day After Thanksgiving",
    date: "2023-11-24",
  },
  christmas_eve: { name: "Christmas Eve", date: "2023-12-24" },
  new_year_eve: { name: "New Year's Eve", date: "2023-12-31" },
};

const OperationalHours = ({ onFinalHolidays }) => {
  const [selectedHolidays, setSelectedHolidays] = useState([]);
  const [customHoliday, setCustomHoliday] = useState("");
  const [customDate, setCustomDate] = useState(new Date());

  useEffect(() => {
    setSelectAll(selectedHolidays.length === Object.keys(holidaysData).length);
  }, [selectedHolidays]);

  const [selectAll, setSelectAll] = useState(false);

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short" };
    return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
  };

  const handleToggleAll = () => {
    const allHolidays = Object.keys(holidaysData);
    const updatedHolidays =
      selectedHolidays.length === allHolidays.length ? [] : allHolidays;

    setSelectedHolidays(updatedHolidays);

    const finalHolidaysData = {};
    updatedHolidays.forEach((selectedHoliday) => {
      if (holidaysData[selectedHoliday]) {
        const { name, date } = holidaysData[selectedHoliday];
        finalHolidaysData[selectedHoliday] = formatDate(date);
      } else {
        const customHolidayData = holidaysData[selectedHoliday];
        finalHolidaysData[selectedHoliday] = formatDate(customHolidayData.date);
      }
    });

    onFinalHolidays(finalHolidaysData);
  };

  const handleToggleHoliday = (holiday) => {
    const updatedHolidays = selectedHolidays.includes(holiday)
      ? selectedHolidays.filter((h) => h !== holiday)
      : [...selectedHolidays, holiday];

    setSelectedHolidays(updatedHolidays);

    const finalHolidaysData = {};
    updatedHolidays.forEach((selectedHoliday) => {
      if (holidaysData[selectedHoliday]) {
        const { name, date } = holidaysData[selectedHoliday];
        finalHolidaysData[selectedHoliday] = formatDate(date);
      } else {
        const customHolidayData = holidaysData[selectedHoliday];
        finalHolidaysData[selectedHoliday] = formatDate(customHolidayData.date);
      }
    });

    onFinalHolidays(finalHolidaysData);
  };

  const updateFinalHolidays = () => {
    const finalHolidaysData = {};

    selectedHolidays.forEach((selectedHoliday) => {
      if (holidaysData[selectedHoliday]) {
        const { name, date } = holidaysData[selectedHoliday];
        finalHolidaysData[selectedHoliday] = formatDate(date);
      } else {
        const customHolidayData = holidaysData[selectedHoliday];
        finalHolidaysData[selectedHoliday] = formatDate(customHolidayData.date);
      }
    });

    onFinalHolidays(finalHolidaysData);
  };

  const handleAddCustomHoliday = () => {
    if (customHoliday.trim() !== "") {
      const customHolidayKey = customHoliday.toLowerCase().replace(/\s/g, "_");
      const customHolidayData = {
        name: customHoliday,
        date: customDate.toISOString().split("T")[0],
      };
  
      if (!selectedHolidays.includes(customHolidayKey)) {
        // Add the new custom holiday to the selectedHolidays array
        const updatedHolidays = [...selectedHolidays, customHolidayKey];
        setSelectedHolidays(updatedHolidays);
  
        // Update holidaysData with the new custom holiday
        holidaysData[customHolidayKey] = customHolidayData;
  
        // Clear input fields
        setCustomHoliday("");
        setCustomDate(new Date());
  
        // Trigger the onFinalHolidays callback with the updated list of selected holidays
        const finalHolidaysData = {};
        updatedHolidays.forEach((selectedHoliday) => {
          if (holidaysData[selectedHoliday]) {
            const { name, date } = holidaysData[selectedHoliday];
            finalHolidaysData[selectedHoliday] = formatDate(date);
          } else {
            const customHolidayData = holidaysData[selectedHoliday];
            finalHolidaysData[selectedHoliday] = formatDate(customHolidayData.date);
          }
        });
        onFinalHolidays(finalHolidaysData);
      }
    }
  };
  
  
  const renderHolidayButtons = () => {
    return Object.keys(holidaysData).map((holiday) => (
      <Col key={holiday} md={4}>
        <Button
          variant={
            selectedHolidays.includes(holiday) ? "warning" : "outline-primary"
          }
          onClick={() => handleToggleHoliday(holiday)}
          className="custom-color-2 w-100"
        >
          {holidaysData[holiday].name}
        </Button>
      </Col>
    ));
  };

  return (
    <Container className="p-5 border shadow mb-5 rounded" fluid>
      <h4 className="main-h4-tag-large m-2">HOLIDAYS - <medium className="text-muted">(Optional)</medium></h4>
      <div className="d-flex justify-content-end">
        <Button
          variant="primary"
          onClick={handleToggleAll}
          className="custom-color"
        >
          {selectAll ? "Unselect All" : "Select All"}
        </Button>
      </div>
      <Row>{renderHolidayButtons()}</Row>
      <div className="d-flex justify-content-center align-items-center">
        <InputGroup className="w-100 mt-2">
          <FormControl
            placeholder="Enter custom holiday"
            value={customHoliday}
            onChange={(e) => setCustomHoliday(e.target.value)}
            className="custom-input mr-3"
          />
        </InputGroup>
        <DatePicker
          selected={customDate}
          onChange={(date) => setCustomDate(date)}
          dateFormat="MMMM d, yyyy"
          className="form-control custom-style-Datepicker"
        />
        <Button
          variant="primary"
          onClick={handleAddCustomHoliday}
          className="custom-color ml-3"
        >
          Add
        </Button>
      </div>
    </Container>
  );
};

export default OperationalHours;
