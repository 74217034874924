import React, { useState } from "react";
import { Container, Button, Accordion, Card, Form } from "react-bootstrap";
import "../assets/css/onboarding.css";

const FAQSection = ({ onFAQDataUpdate }) => {
  const [faqs, setFAQs] = useState([]);
  const [newFAQ, setNewFAQ] = useState({ question: "", answer: "" });
  const [editingIndex, setEditingIndex] = useState(null);

  const addFAQ = () => {

    if (newFAQ.question && newFAQ.answer) {
      if (editingIndex !== null) {
        const updatedFAQs = [...faqs];
        updatedFAQs[editingIndex] = {
          question: newFAQ.question,
          answer: newFAQ.answer,
        };
        setFAQs(updatedFAQs);
        setEditingIndex(null);
        onFAQDataUpdate(updatedFAQs);
      } else {
        const updatedFAQs = [
          ...faqs,
          { question: newFAQ.question, answer: newFAQ.answer },
        ];
        setFAQs(updatedFAQs);

        onFAQDataUpdate(updatedFAQs);
      }

      setNewFAQ({ question: "", answer: "" });
    }
  };

  const editFAQ = (index) => {
    setNewFAQ({ ...faqs[index] });
    setEditingIndex(index);
  };

  const deleteFAQ = (index) => {
    const updatedFAQs = [...faqs];
    updatedFAQs.splice(index, 1);
    setFAQs(updatedFAQs);
    setEditingIndex(null);

    onFAQDataUpdate(updatedFAQs);
  };

  return (
    <Container className="p-5 border shadow mb-2 mt-5 rounded" fluid>
      <h4 className="main-h4-tag-large mb-3">FAQs Section - <medium className="text-muted">(Optional)</medium></h4>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          addFAQ();
        }}
        className="w-100"
      >
        <Form.Group controlId="formQuestion" className="mt-3 custom-p-tag">
          <Form.Label>Question</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your question"
            value={newFAQ.question}
            onChange={(e) => setNewFAQ({ ...newFAQ, question: e.target.value })}
            className="p-2"
          />
        </Form.Group>

        <Form.Group controlId="formAnswer" className="mt-3 custom-p-tag">
          <Form.Label>Answer</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter your answer"
            value={newFAQ.answer}
            onChange={(e) => setNewFAQ({ ...newFAQ, answer: e.target.value })}
            className="p-2"
          />
        </Form.Group>

        <Button variant="primary" className="custom-button" onClick={addFAQ}>
          {editingIndex !== null ? "Update" : "Add"} FAQ
        </Button>
      </Form>

      <Accordion className="w-100">
        {faqs.map((faq, index) => (
          <Card key={index} className="custom-card-style p-0">
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header className="mb-2">
                Question: {faq.question}
              </Accordion.Header>
              <Accordion.Body className="p-2 custom-accordion-body">
                <div className="float-right mt-5">
                  <Button
                    variant="warning"
                    onClick={() => editFAQ(index)}
                    className="custom-button"
                  >
                    Update
                  </Button>{" "}
                  <Button
                    variant="danger"
                    onClick={() => deleteFAQ(index)}
                    className="custom-button"
                  >
                    Delete
                  </Button>
                </div>
                <div>Answer: {faq.answer}</div>
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        ))}
      </Accordion>
    </Container>
  );
};

export default FAQSection;
