import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import React, {useState, useEffect} from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {useNavigate, useLocation} from "react-router-dom";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {bouncy} from "ldrs";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import '../assets/css/paymentPage.css'
import {BackEndApi, FrontEndApi} from "../Config/config";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TermOfServiceModal from "./Modals/termOfServiceModal";

bouncy.register();

export default function CheckoutForm({
                                         priceID,
                                         setupFee
                                     }) {
    const [fullName, setFullName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const defaultTheme = createTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState("");
    const [lastNameErrorText, setLastNameErrorText] = useState("");
    const [companyNameErrorText, setCompanyNameErrorText] = useState("");
    const [emailErrorText, setEmailErrorText] = useState()
    const [phoneNumberErrorText, setPhoneNumberErrorText] = useState()
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [termsErrorText, setTermsErrorText] = useState("");
    const [showTermOfService, setShowTermOfService] = useState(false)
    const stripe = useStripe();
    const elements = useElements();
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                'color': '#32325d',
                'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
                'fontSmoothing': 'antialiased',
                'fontSize': '16px',
                'border': '1px solid #172e57',
                '::placeholder': {
                    color: '#172e57',
                },
            },
            invalid: {
                color: '#fd0000',
                iconColor: '#fd0000',
            },
        },
    };

    const handleShowTermOfServiceModal = () => {
        console.log("TEST")
        setShowTermOfService(true);
    };

    const handleCloseModal = () => {
        setShowTermOfService(false);
    };

    const handleSubmitSub = async (event) => {
        let customer;
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const fullName =
            document.getElementById("firstName").value +
            " " +
            document.getElementById("lastName").value;
        const companyName = document.getElementById("companyName").value;
        const phoneValue = phone
        const email = document.getElementById("email").value;
        if (!firstName || !lastName || !companyName || !phone || !email) {
            if (!firstName) {
                setFirstNameErrorText("Please Enter Your First Name");
                setIsLoading(false);
            }
            if (!lastName) {
                setLastNameErrorText("Please Enter Your Last Name");
                setIsLoading(false);
            }
            if (!companyName) {
                setCompanyNameErrorText("Please Enter Your Business Name");
                setIsLoading(false);
            }
            if (!phone) {
                setPhoneNumberErrorText("Please Enter Your Phone Number");
                setIsLoading(false);
            }
            if (!email) {
                setEmailErrorText("Please Enter Your Email");
                setIsLoading(false);
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setEmailErrorText("Please Enter A Valid Email");
                setIsLoading(false);
            }

            const phoneRegex = /^[0-9]{10}$/;
            if (!phoneRegex.test(phoneValue)) {
                setPhoneNumberErrorText("Please Enter A Valid Phone Number - EX:18667480009 ");
                setIsLoading(false);
            }
            toast.error("Please fill in all required fields");
            return
        }
        if (!agreeToTerms) {
            setTermsErrorText("You must agree to the terms of service");
            setIsLoading(false);
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: email,
                name: fullName,
                phone: phoneValue,
            },
            metadata: {business: companyName},
        });

        if (result.error) {

            toast.error(result.error.message);
            setIsLoading(false);

        } else {
            try {
                const res = await axios.post(`${BackEndApi}payments/stripePayment/`, {
                    'payment_method': result.paymentMethod.id, 'email': email, name: fullName,
                    phone: phoneValue, metadata: {business: companyName}, price: priceID, setupFee: setupFee
                });
                const {client_secret, status, customer, subscriptionID} = res.data;
                if (status === 'requires_action') {
                    stripe.confirmCardPayment(client_secret).then(function (result) {
                        if (result.error) {
                            toast.error(result.error);
                            setIsLoading(false);
                        }
                    });
                } else {
                    let data = {
                        name: fullName,
                        businessName: companyName,
                        phoneNumber: phone,
                        email: email,
                        customerID: customer?.id,
                        subscriptionID: subscriptionID
                    };

                    let config = {
                        method: "post",
                        maxBodyLength: Infinity,
                        url: `${BackEndApi}payments`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    axios(config)
                        .then(response => {
                            if (response.data) {
                                const id = response?.data.id;
                                toast.success("Payment successful! Redirecting to the Onboarding Page.");
                                setTimeout(() => {
                                    window.location.href = `${FrontEndApi}/onboarding?customer_id=${id}`;
                                }, 2000);
                            }
                        })
                        .catch(error => {
                            toast.error("Account Creation failed. Please try again.");
                        });

                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error.response.data.error);

            }
        }
    };

    return (
        <div className="d-flex justify-content-center payment-view-margin-tablet">
            <div>
                <h5 className={"payment-headers mb-4"}>You’re almost there</h5>
                <p className={"payment-tags"}>
                    We just need a few more details to complete
                    your signup.
                </p>
                <br/>
                <h5 className={"payment-headers mb-4"}>Create your account</h5>
                <div>
                    <ThemeProvider theme={defaultTheme}>
                        <Box>
                            <form onSubmit={handleSubmitSub}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            autoComplete="given-name"
                                            name="firstName"
                                            required
                                            fullWidth
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            value={firstName}
                                            error={!!firstNameErrorText}
                                            helperText={firstNameErrorText}
                                            onChange={(e) => {
                                                setFirstName(e.target.value);
                                                setFirstNameErrorText("");
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="family-name"
                                            value={lastName}
                                            error={!!lastNameErrorText}
                                            helperText={lastNameErrorText}
                                            onChange={(e) => {
                                                setLastName(e.target.value);
                                                setLastNameErrorText("");
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="companyName"
                                            label="Business Name"
                                            name="companyName"
                                            autoComplete="companyName"
                                            value={companyName}
                                            error={!!companyNameErrorText}
                                            helperText={companyNameErrorText}
                                            onChange={(e) => {
                                                setCompanyName(e.target.value);
                                                setCompanyNameErrorText("");
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} className={""}>

                                        <PhoneInput
                                            country={'us'}
                                            required
                                            fullWidth
                                            id="phone"
                                            type="number"
                                            label="Phone Number"
                                            name="phone"
                                            autoComplete="tel"
                                            value={phone}
                                            error={!!phoneNumberErrorText}
                                            helperText={phoneNumberErrorText}
                                            containerStyle={{width: '100%', height: '35px'}}
                                            inputStyle={{fontSize: '14px', height: '100%'}}
                                            dropdownStyle={{maxHeight: '150px'}}
                                            onChange={(value) => {
                                                setPhone(value);
                                                setPhoneNumberErrorText("");
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            fieldName="Email"
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            value={email}
                                            error={!!emailErrorText}
                                            helperText={emailErrorText}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setEmailErrorText("");
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </ThemeProvider>
                </div>
                <br/>
                <br/>
                <h3 className={"payment-headers"}>Place Your Order</h3>

                <form id="payment-form" onSubmit={handleSubmitSub}>
                    <CardElement options={CARD_ELEMENT_OPTIONS} className={''}/>
                    <div className={'mt-2 d-flex justify-content-end'}>
                        <img
                            alt="..."
                            src={require("../assets/img/stripe-badge-transparent.png")}
                            className=" w-50"
                        />
                    </div>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreeToTerms}
                                    onChange={(e) => {
                                        setAgreeToTerms(e.target.checked);
                                        setTermsErrorText("");
                                    }}
                                    name="agreeToTerms"
                                    color="primary"
                                />
                            }
                            label={
                                <>
                                    <small>
                                    Your use of LiveSupportRep is governed by our{" "}
                                    <span
                                        onClick={handleShowTermOfServiceModal}
                                        style={{color: "blue", textDecoration: "underline", cursor: "pointer"}}
                                    >
                    Terms of Service
                </span>
                                    , to which you'll agree by checking the box to the left and by using our services.
                                    </small>
                                </>
                            }
                        />
                        {termsErrorText && <FormHelperText error>{termsErrorText}</FormHelperText>}
                    </Grid>

                    <div className="mt-3 d-flex justify-content-end">
                        <Button
                            className={"pay-button"}
                            disabled={isLoading || !stripe || !elements}
                            id="submit"
                        >
                            {isLoading ? "Processing..." : "PAY NOW"}
                        </Button>
                    </div>
                </form>
                <TermOfServiceModal show={showTermOfService} onHide={handleCloseModal}/>
            </div>
        </div>
    );
}