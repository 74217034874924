import {Container, Row, Col, Button} from "reactstrap";
import OrderForm from "../components/Orderform";
import {React, useState, useEffect} from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {BackEndApi, StripePublishKey} from "../Config/config";
import axios from "axios";

const Payments = () => {
    const stripePromise = loadStripe(StripePublishKey);
    const [productData, setProductData] = useState("");
    const [productPriceData, setProductPriceData] = useState("");
    const urlParams = new URLSearchParams(window.location.search);
    const productID = urlParams.get("subs");
    const setupCode = urlParams.get("setup");
    let productPrice = productPriceData.unit_amount / 100;
    let priceID = productPriceData.id
    let discount = 0;
    let futureCredits = 0;
    let setupCharges = {'1293834832': '4999', '984378573484': '0'}
    const [setupFee, setSetupFee] = useState()
    let totalToPay = productPrice - discount + setupFee;


    useEffect(() => {
        const fetchData = async () => {
            if (productID != null) {
                try {
                    let product = await axios.post(`${BackEndApi}stripe/product/`, {'productID': productID})
                    setProductData(product.data);

                    if (product) {
                        let price = await axios.post(`${BackEndApi}stripe/price/`, {'priceID': product.data.default_price});
                        setProductPriceData(price.data);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchData();
    }, [productID]);

    useEffect(() => {
        let charge = setupCharges[setupCode]
        setSetupFee(charge / 100);
    }, [setupCode]);

    return (
        <Container fluid>
            <Row>
                {/* 60% section */}
                <Col lg={7} md={12} sm={12}>
                    <Row lg={4} md={2} sm={12}>
                        <div className="image mt-4 ml-3">
                            <img
                                alt="..."
                                src={require("../assets/img/Livesupportrep.png")}
                                className="img-fluid img-size-100"
                            />
                        </div>

                    </Row>
                    <Row>
                        {/* 70% section containing Signup and Stripe payment */}
                        <Col lg={3} md={2} sm={12}>
                        </Col>
                        <Col lg={8} md={9} sm={12}>

                            <Row>
                                <Elements stripe={stripePromise}>
                                    <Col>
                                        <OrderForm
                                            priceID={priceID}
                                            setupFee={setupCharges[setupCode]}
                                        />
                                    </Col>
                                </Elements>
                            </Row>
                        </Col>
                        <Col lg={1} md={9} sm={12}></Col>
                    </Row>
                </Col>
                {/* 40% section */}
                <Col
                    lg={5}
                    md={12}
                    sm={12}
                    style={{
                        backgroundImage: `url(${require("../assets/img/PaymentPageOrder.jpg")})`,
                        backgroundSize: "cover",
                        backgroundRepeat: 'no-repeat',
                        minHeight: "100vh",
                        backgroundPosition: "right",
                    }}
                >
                    <Row style={{minHeight: "90vh"}}>
                        <Col>
                            <div className={"main-order-details back-static order-overview-glass-morph mt-5 mb-3"}>
                                <div>
                                    <h6 className={"order-overview"}>Order Overview</h6>
                                    <br/>
                                    <p className={"your-plan-header"}>Your Plan</p>
                                    <h5 className={"minute-included-tag"}>{productData?.metadata?.quota} Minutes
                                        included</h5>
                                    <h1 className={"main-price-tag"}>${productPrice}<span>/mo</span></h1>
                                    <hr/>
                                    <div className="d-flex justify-content-between general-orderOverview-text">
                                        <p>Setup Fee</p>
                                        <p>{setupFee === 0 ? 'FREE' : `$${setupFee}`}</p>
                                    </div>

                                    <div className="d-flex justify-content-between general-orderOverview-text">
                                        <p>Total Cost per Month</p>
                                        <p>${productPrice}</p>
                                    </div>

                                    <hr/>

                                    <div className="d-flex justify-content-between general-orderOverview-text">
                                        <p>Discount</p>
                                        <p>- ${discount.toFixed(2)}</p>
                                    </div>

                                    {/*<p className={"total-pay-text"}>Future Credits:</p>*/}

                                    {/*<div className="d-flex justify-content-between general-orderOverview-text">*/}
                                    {/*  <p>Jan 2024</p>*/}
                                    {/*  <p>${futureCredits.toFixed(2)}</p>*/}
                                    {/*</div>*/}

                                    {/*<div className="d-flex justify-content-between general-orderOverview-text">*/}
                                    {/*  <p>Feb 2024</p>*/}
                                    {/*  <p>${futureCredits.toFixed(2)}</p>*/}
                                    {/*</div>*/}
                                    <hr/>
                                    <div
                                        className="mt-4 d-flex justify-content-between general-orderOverview-text total-pay-text">
                                        <p>Total to Pay Today</p>
                                        <p>${totalToPay.toFixed(2)}</p>
                                    </div>
                                    <hr/>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Payments;
