import React, { useState, useEffect } from "react";
import {
  Button,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
//import Dropdown from 'react-bootstrap/Dropdown';
import "../assets/css/onboarding.css";
import { BsCopy } from 'react-icons/bs';
//import { Dropdown } from "reactstrap";
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
 
const WorkingHoursComponent = ({ onDataUpdate }) => {
  const [selectedDays, setSelectedDays] = useState([1, 2, 3, 4, 5]);
  const [selectedTimezone, setSelectedTimezone] = useState("");

  const [workingHours, setWorkingHours] = useState({
    Monday: {},
    Tuesday: {},
    Wednesday: {},
    Thursday: {},
    Friday: {},
    Saturday: {},
    Sunday: {},
  });

  useEffect(() => {
    const formattedData = formatDataForBackend();
    onDataUpdate(formattedData);
  }, [workingHours, selectedTimezone]);

  const handleDayClick = (dayIndex) => {
    let updatedDays = [...selectedDays];

    if (updatedDays.includes(dayIndex)) {
      updatedDays.splice(updatedDays.indexOf(dayIndex), 1);
    } else {
      updatedDays.push(dayIndex);
    }

    updatedDays = updatedDays.sort((a, b) => a - b);

    setSelectedDays(updatedDays);

    daysOfWeek.forEach((day, index) => {
      if (!updatedDays.includes(index)) {
        setWorkingHours((prevHours) => ({
          ...prevHours,
          [day]: {},
        }));
      }
    });
  };

  const handleWorkingHoursChange = (day, field, value) => {
    setWorkingHours((prevHours) => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        [field]: value,
      },
    }));
  };

  const handleCopyToAll = () => {
    const copiedHours = workingHours[daysOfWeek[selectedDays[0]]];
    selectedDays.forEach((dayIndex) => {
      const day = daysOfWeek[dayIndex];
      setWorkingHours((prevHours) => ({
        ...prevHours,
        [day]: { ...copiedHours },
      }));
    });
  };

  const formatDataForBackend = () => {
    const formattedData = {};

    daysOfWeek.forEach((day) => {
      formattedData[day.toLowerCase()] = `${workingHours[day].start || "Off"
        }-${workingHours[day].end || "Day"}`;
    });

    return {selectedTimezone, formattedData};
  };

  const handleTimezoneSelect = (event) => {
    const timezone = event.target.value;
    setSelectedTimezone(timezone);
  };

  return (
    <Container className="p-3 p-md-5 border shadow mb-5 rounded" fluid>
      <h4 className="main-h4-tag-large m-3">WORKING HOURS - <medium className="text-muted">(Optional)</medium></h4>
      <Row className={"d-flex align-items-center"}>
        <Col md={7}>
      <ButtonGroup className="w-25 m-2 ">
        {daysOfWeek.map((day, index) => (
          <Button
            key={day}
            variant={selectedDays.includes(index) ? "primary" : "secondary"}
            onClick={() => handleDayClick(index)}
            className="m-1 p-2 rounded-circle mb-3"
          >
            {day.charAt(0)}
          </Button>
        ))}
      </ButtonGroup>
      </Col>
    
      <Col md={5}  >
        <Form >
        <Form.Group
            className="mb-3 w-50"
            controlId="formBasicState"
        >
          <Form.Control
              as="select"
              onChange={handleTimezoneSelect}
              value={selectedTimezone}
          >
            <option value="" disabled>
              Select a TimeZone
            </option>
            <option value="Eastern Time (ET)">Eastern Time (ET)</option>
            <option value="Central Time (CT)">Central Time (CT)</option>
            <option value="Mountain Time (MT)">Mountain Time (MT)</option>
            <option value="Pacific Time (PT)">Pacific Time (PT)</option>
          </Form.Control>
        </Form.Group>
        </Form>
      </Col>

      </Row>
      <Form className="TimeSelection mb-4 mr-3 ml-3 custom-p-tag">
        {selectedDays.map((dayIndex) => {
          const day = daysOfWeek[dayIndex];
          return (
            <Row className="d-flex align-items-center" key={day}>
              <Col md={2}>
                <p className="mt-3 custom-p-tag">{day}</p>
              </Col>
              <Col xs={12} md={8} className="d-flex align-items-center">
                <Form.Group
                  controlId={`${day}-start-time`}
                  className="mr-3 w-100"
                >
                  <Form.Control
                    type="time"
                    value={workingHours[day].start}
                    onChange={(e) =>
                      handleWorkingHoursChange(day, "start", e.target.value)
                    }
                  />
                </Form.Group>
                <p className="mt-2 mr-3">to</p>
                <Form.Group
                  controlId={`${day}-end-time`}
                  className="mr-3 w-100"
                >
                  <Form.Control
                    type="time"
                    value={workingHours[day].end}
                    onChange={(e) =>
                      handleWorkingHoursChange(day, "end", e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                {dayIndex === selectedDays[0] && (
                  <p
                    onClick={handleCopyToAll}
                    style={{ cursor: "pointer" }}
                  >
                    <BsCopy size={15} className="mr-2" />
                    Copy to All
                  </p>
                )}
              </Col>
            </Row>
          );
        })}


      </Form>
    </Container>
  );
};

export default WorkingHoursComponent;
