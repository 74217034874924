import React, {useEffect, useState} from "react";
import {Modal, Button, Form} from "react-bootstrap";
import {ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const TermOfServiceModal = ({show, onHide}) => {

    return (
        <Modal show={show} onHide={onHide} size={"lg"}>
            <ModalHeader style={{ color: "#113865", fontWeight:"bolder"}}>
                TERMS OF SERVICE
            </ModalHeader>
            <ModalBody>
                <p>
                    <strong>These terms of service (which we'll refer to below as the "Agreement") are a contract
                        between you and LiveSupportRep,Inc. A corporation that carries on business under the business
                        name “LiveSupportRep”) (which we'll refer to below as just "LiveSupportRep").</strong><br/> This
                    Agreement will govern your use of LiveSupportRep's products and services and all other aspects of
                    your commercial relationship with LiveSupportRep, so please read it carefully. By using or
                    continuing to use LiveSupportRep's products and/or services, you agree to each of the terms and
                    conditions below.
                </p>
                <p>
                    <strong>LiveSupportRep’s SERVICES:</strong><br />
                    In exchange for the payments associated with the plan you've selected, LiveSupportRep will make its live call answering services (the "Services") available to you in accordance with this Agreement and the plan's terms, which are shown in the confirmation email you received following sign-up.
                </p>
                <p>
                    You agree to access and use the Services solely for lawful business or professional purposes and to abide by LiveSupportRep's policies regarding the use of the Services, as those policies may change from time to time. You further agree not to sell, transfer, license, or otherwise make available the Services to any third person without LiveSupportRep 's prior written consent. You acknowledge that particular features of the Services may change from time to time, with or without prior notice.
                </p>
                <p>
                    You agree that the software and systems through which LiveSupportRep delivers the Services are proprietary to LiveSupportRep and shall in all events remain the exclusive property of LiveSupportRep.
                </p>
                <p>
                    After sign up, you'll receive credentials (i.e., a username and password) with which you can access the Services and/or various features associated with the Services (e.g., the written messages that Live Support Reps agents take on your behalf) (your "Credentials"). You acknowledge that it is your sole responsibility to safeguard your Credentials from unauthorized disclosure or use, and you authorize LiveSupportRep to provide access to the Services to any person who presents your Credentials.
                </p>
                <p>
                    LiveSupportRep may offer integrations with various other products and services, some of which require separate credentials to access. In the event you provide your Credentials to any other product or service to LiveSupportRep, you authorize LiveSupportRep to use those credentials, and to access and use the account(s) with which they are associated and any data contained therein, for any purpose reasonably related to LiveSupportRep's provision of the Services.
                </p>
                <p>
                    In order to deliver the Services, LiveSupportRep needs your permission to perform various tasks with the calls and messages that it handles on your behalf. Accordingly, you agree that LiveSupportRep may:
                    <ol>
                        <li>Patch calls from third parties to you to the telephone number(s) that you provide to LiveSupportRep for those purposes.</li>
                        <li>Create and maintain written records of messages from third parties to you that LiveSupportRep takes on your behalf.</li>
                        <li>Send notifications that include the contents of, and other information related to, such messages to the email address(es) and/or telephone number(s) that you provide to LiveSupportRep for those purposes.</li>
                        <li>Collect, store, and use for business purposes whatever personal or business information you provide to LiveSupportRep in connection with the Services.</li>
                        <li>Listen to and record your voice while using or interacting with the Services.</li>
                        <li>Contact you using the email address(es), telephone number(s), and whatever other contact information you provide to LiveSupportRep.</li>
                        <li>Take all other steps that are reasonably necessary in order for LiveSupportRep to deliver the Services.</li>
                    </ol>
                </p>
                <p>
                    You agree to inform LiveSupportRep immediately and in writing if any of your email address(es) or telephone number(s) should no longer receive patched calls or message notifications; you acknowledge that, unless and until you do so, LiveSupportRep will continue to patch calls and/or send such notifications to the email address(es) and/or telephone number(s) that you previously provided for those purposes.
                </p>
                <p>
                    You further agree to obtain, and acknowledge that you are solely responsible for obtaining, each of the permissions described above from your employees, agents, and all other persons who use or interact with the Services in any way on your behalf.
                    Similarly, you agree to obtain, and acknowledge that you are solely responsible for obtaining, each of the permissions described above from third parties who use or may use the Services to communicate with you.
                </p>
                <p>
                    <strong>BILLING AND PAYMENT:</strong><br />
                    You will incur the monthly charge(s) associated with the plan you've selected for each whole or partial month during which this Agreement remains in effect, plus any applicable taxes, duties, or other additional payments required by law, which you authorize LiveSupportRep to collect from you. You are responsible for the full and on-time payment of all charges invoiced to you.
                </p>
                <p>
                    If you exceed your allotted minutes in a month, you will be charged for all additional minutes used during that month at the overage rate associated with the plan you've selected. You acknowledge that you will be charged in whole-minute increments, and that any partial minutes will be rounded up to the nearest whole minute. You further acknowledge that chargeable call time includes all time spent by LiveSupportRep's agents answering calls or performing other work on your behalf, including, for example, hold time and time spent patching a customer call forward.
                </p>
                <p>
                    You agree to pay LiveSupportRep the full amount of each monthly invoice you receive by the due date listed on the invoice. You authorize LiveSupportRep to send your invoices to the primary email address you provided during sign-up, and to inform LiveSupportRep immediately and in writing of any change to that email address or your other billing information.
                </p>
                <p>
                    If you have provided LiveSupportRep with your credit card information or enabled any other automatic payment method (e.g., ACH etc.), you authorize LiveSupportRep to charge the amount of each of your monthly invoices to your credit card or via such other automatic payment method immediately upon each invoice's issuance. You further authorize LiveSupportRep to charge your credit card or such other automatic payment method immediately in the event that you accrue any overage charges. You agree to inform LiveSupportRep immediately of any change in your credit card or other payment information, which shall be in writing.
                </p>
                <p>
                    You guarantee and warrant that you are the legal owner of such a credit card or the account associated with your automatic payment method, and that you are legally authorized to accept charges from LiveSupportRep.
                </p>
                <p>
                    If you fail to make any payment due to LiveSupportRep under the Agreement by the due date for payment, then you shall pay interest on the overdue amount at a rate of 3.0% per cent per annum. Such interest shall accrue on a daily basis from the due date until actual payment of the overdue amount. You shall pay the interest together with the overdue amount.
                </p>
                <p>
                    You shall pay all amounts due under the Agreement in full without any set-off, counterclaim, deduction or withholding (except for any deduction or withholding required by law). LiveSupportRep may at any time, without limiting its other rights or remedies, set off any amount owing to it by you against any amount payable by LiveSupportRep to you.
                </p>
                <p>
                    <strong>TERM AND TERMINATION:</strong><br />
                    This Agreement will continue on a month-to-month basis until you or LiveSupportRep terminates it in accordance with this paragraph.
                </p>
                <p>
                    This Agreement will be in effect for a minimum period of 90 calendar days. Following that period, either you or LiveSupportRep may terminate this Agreement at any time, for any or no reason, upon written notice to the other party. Notwithstanding any of the foregoing, LiveSupportRep may terminate this Agreement at any time if, in its sole discretion, it determines that you have violated any provision of this Agreement or LiveSupportRep's policies regarding the use of the Services, as those policies may change from time to time. Notwithstanding any of the foregoing, the parties expressly acknowledge that the provisions of the following sections ("DISCLAIMER OF WARRANTIES, REMEDIES, AND INDEMNIFICATION" and "MISCELLANEOUS") will survive the termination of this Agreement, and that the termination of this Agreement will not be deemed to extinguish, or construed as a waiver of LiveSupportRep's right to enforce, any obligation that arose during the term of this Agreement.
                </p>
                <p>
                    Upon termination of this Agreement by either party as contemplated above, you will be charged and responsible for paying a prorated amount of monthly fees and charges for the month of termination, up to the point at which this Agreement is terminated.
                </p>
                <p>
                    Upon termination of this Agreement for any reason, you shall immediately pay to LiveSupportRep all of LiveSupportRep's outstanding unpaid invoices and interest and, in respect of Services supplied but for which no invoice has been submitted, LiveSupportRep may submit an invoice, which shall be payable immediately upon receipt.
                </p>
                <p>
                    <strong>DISCLAIMER OF WARRANTIES:</strong><br />
                    You agree that your use of the services will be at your sole risk, and that the services are provided on an "as is" and "as available" basis.
                </p>
                <p>
                    LiveSupportRep makes no express or implied warranties, conditions, or representations, and LiveSupportRep expressly disclaims all warranties and conditions, including but not limited to, the implied warranties of merchantability, title, fitness for a particular purpose, and non-infringement.
                </p>
                <p>
                    LiveSupportRep does not warrant that (a) the services will meet your requirements, (b) the services will be uninterrupted, timely, secure, or error-free, (c) the results that may be obtained from use of the services will be accurate or reliable, or (d) the quality of any products, services, information, or other material purchased or obtained by you through the services will meet your expectations.
                </p>
                <p>
                    No advice or information, whether oral or written, obtained by you from LiveSupportRep or through or from the services shall create any warranty not expressly stated in the Agreement.
                </p>
                <p>
                    <strong>LIMITATION OF LIABILITY:</strong><br />
                    To the maximum extent permitted by applicable law, you agree that LiveSupportRep shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if LiveSupportRep has been advised of the possibility of such damages), resulting from: (a) the use or the inability to use the services; (b) the cost of procurement of substitute goods and services resulting from any goods, data, information, or services purchased or obtained or messages received or transactions entered into through or from the services; (c) unauthorized access to or alteration of your transmissions or data; (d) statements or conduct of any third party on the services; or (e) any other matter relating to the services.
                </p>
                <p>
                    If, notwithstanding the other provisions of this Agreement, LiveSupportRep is found to be liable to you for any damage or loss which arises out of or is in any way connected with your use of the services, LiveSupportRep’s liability shall in no event exceed the greater of (a) the total of any fees with respect to the service or feature of the services paid in the six months prior to the date of the initial claim made against LiveSupportRep, or (b) $100.00.
                </p>
                <p>
                    Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so some of the above limitations may not apply to you. In such jurisdictions, LiveSupportRep’s liability is limited to the greatest extent permitted by law.
                </p>
                <p>
                    <strong>INDEMNIFICATION:</strong><br />
                    You agree to indemnify and hold LiveSupportRep, its officers, directors, employees, agents, shareholders, licensors, suppliers, and any third-party information providers to the services harmless from and against all claims, losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of this Agreement, or any activity related to your account (including negligent or wrongful conduct), by you or any other person accessing the services using your credentials.
                </p>
                <p>
                    <strong>MISCELLANEOUS:</strong><br />
                    This Agreement will be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to its conflict of law provisions or your actual state or country of residence.
                </p>
                <p>
                    If for any reason a court of competent jurisdiction finds any provision or portion of this Agreement to be unenforceable, the remainder of this Agreement will continue in full force and effect.
                </p>
                <p>
                    This Agreement constitutes the entire Agreement between you and LiveSupportRep and governs your use of the services, superseding any prior agreements between you and LiveSupportRep (including, but not limited to, any prior versions of this Agreement).
                </p>
                <p>
                    You also may be subject to additional terms and conditions that may apply when you use or purchase certain other LiveSupportRep services, affiliate services, third-party content, or third-party software.
                </p>
                <p>
                    The failure of LiveSupportRep to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.
                </p>
                <p>
                    The section titles in this Agreement are for convenience only and have no legal or contractual effect.
                </p>
            </ModalBody>
        </Modal>
);
};

export default TermOfServiceModal;
