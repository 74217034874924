import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PersonalAndCompanyDetails from "../components/personal&CompanyDetails";
import WorkingHoursComponent from "../components/WorkingHoursComponent";
import HolidayList from "components/HolidayList";
import FAQSection from "components/FAQSection";
import axios from "axios";
import "../assets/css/onboarding.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
import { BackEndApi, LsrCustomerPortal } from "../Config/config";

const Onboarding = () => {
  const [finalHolidays, setFinalHolidays] = useState({});
  const [workingHoursData, setWorkingHoursData] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const formData = useSelector((state) => state.form);
  const [nameErrorText, setNameErrorText] = useState();
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [businessNameError, setBusinessNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [businessOverviewError, setBusinessOverviewError] = useState();
  const [spinner, setSpinner] = useState(false);

  const handleFAQData = (formattedFAQData) => {
    setFaqData(formattedFAQData);
  };

  const handleFinalHolidays = (holidays) => {
    setFinalHolidays(holidays);
  };

  const handleWorkingHoursData = (workingHours) => {
    setWorkingHoursData(workingHours);
  };

  const handleSubmit = async () => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{11}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?.&])[A-Za-z\d@$!%*?.&]{8,}$/;

    const nameError = !formData.name;
    const businessNameError = !formData.businessName;
    const businessOverviewError = !formData.businessOverview;
    const emailError = !emailRegex.test(formData.email);
    const phoneNumberError = !phoneRegex.test(formData.phoneNumber);
    const passwordError = !passwordRegex.test(formData.newPassword);

    setNameError(nameError);
    setBusinessNameError(businessNameError);
    setBusinessOverviewError(businessOverviewError);
    setEmailError(emailError);
    setPhoneNumberError(phoneNumberError);
    setPasswordError(passwordError);

    if (
        nameError ||
        businessNameError ||
        businessOverviewError ||
        emailError ||
        phoneNumberError ||
        passwordError
    ) {
      toast.error("Please fill out all required fields.", {
        onClose: () => {
          setSpinner(false);
        },
        autoClose: 1000,
      });
      return;
    }

    const finalPayload = {
      name: formData.name,
      email: formData.email,
      password: formData.newPassword,
      phoneNumber: formData.phoneNumber,
      account: {
        accountID: formData.accountID,
        businessName: formData.businessName,
        greetingText: formData.greetingText,
        role: formData.role,
        hoursOfOperation: {
          timezone: workingHoursData.selectedTimezone ,
          days: workingHoursData.formattedData,
          holidays: finalHolidays || {},
        },
        overview: {
          address: formData.address,
          phoneNumber: formData.businessPhoneNumber,
          email: formData.businessEmail,
          businessOverview: formData.businessOverview,
        },
        faqs: faqData || {},
      },
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BackEndApi}auth/register`,
      headers: {
        "Content-Type": "application/json",
      },
      data: finalPayload,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Congratulations! Your onboarding is successful.", {
          autoClose: 1000,
          onClose: () => {
            setSpinner(false);
          },
        });

        window.location.href = LsrCustomerPortal;
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col
          lg={9}
          md={12}
          sm={12}
          style={{ overflowY: "auto", maxHeight: "100vh" }}
        >
          <div>
            <h1 className="main-h1-heading-large d-flex justify-content-center m-5">
              ONBOARDING
            </h1>
            <PersonalAndCompanyDetails
              nameError={nameError}
              setNameError={setNameError}
              emailError={emailError}
              setEmailError={setEmailError}
              passwordError={passwordError}
              setPasswordError={setPasswordError}
              businessNameError={businessNameError}
              setBusinessNameError={setBusinessNameError}
              businessOverviewError={businessOverviewError}
              setBusinessOverviewError={setBusinessOverviewError}
              phoneNumberError={phoneNumberError}
              setPhoneNumberError={setPhoneNumberError}
            />
            <WorkingHoursComponent onDataUpdate={handleWorkingHoursData} />
            <HolidayList onFinalHolidays={handleFinalHolidays} />
            <FAQSection onFAQDataUpdate={handleFAQData} />
          </div>
          <div className={"d-flex justify-content-end"}>
            <Button
              variant="primary"
              type="submit"
              disabled={spinner}
              onClick={() => {
                setSpinner(true);
                handleSubmit();
              }}
              className="custom-button mb-4 w-25 bg"
            >
              {spinner ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: "10px" }}
                  />
                  Submitting
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Col>
        <Col
          lg={3}
          md={12}
          sm={12}
          style={{
            backgroundImage: `url(${require("../assets/img/OnoardingAni.jpg")})`,
          }}
          className={"side-graphics"}
        >
          <div className="image m-4">
            <img
              alt="..."
              src={require("../assets/img/Livesupportrep(White).png")}
              className="img-fluid img-size-100"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Onboarding;
