import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import store from './Redux/store';
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
    <Provider store={store}>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss={true}
            draggable={true}
            pauseOnHover={true}
        >
        </ToastContainer>
        <App />
    </Provider>,
    </React.StrictMode>
);
