import {formDataUpdated} from "../constant";

//currently being used for testing purposes
let randInt = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
randInt = randInt.toString();

export const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    newPassword: "",
    accountID: randInt,
    businessName: "",
    greetingText: 'TYFC "ABC". HMIHY?',
    role: "Receptionist",
    address: "",
    businessPhoneNumber: "",
    businessEmail: "",
    businessOverview: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case formDataUpdated:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
